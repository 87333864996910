body {
  background: rgb(39, 41, 48);
}

.header-text {
  text-align: center;
  color: #ddbb88;
  font-size: 24px;
  margin-top: 120px;
  font-family: "Roboto", sans-serif;
}

.leaderboard {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

table {
  width: 80%;
  background-color: transparent;
  border-collapse: collapse;
  color: white;
}

th, td {
  padding: 10px;
  text-align: center;
  color: white;
  font-family: "Roboto", sans-serif;
}

th {
  background-color: transparent;
  color: rgb(163, 152, 152);
  font-weight: bold;
  font-size: 26px;
}

td {
  padding: 20px 10px;
  border-top: 1px solid #ffaa00;
  font-size: 18px;
}

td:first-child {
  border-left: none;
}

td:last-child {
  border-right: none;
}

.page-buttons-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.page-button {
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: rgb(211, 206, 206);
  transition: color 0.3s ease 0s;
}

.page-button:hover {
  color: rgba(19, 160, 216, 0.8);
}

.rank {
  font-weight: bold;
  font-size: 22px;
}

.gold {
  color: rgb(255, 217, 0);
}

.silver {
  color: rgb(135, 135, 135);
}

.bronze {
  color: #cd7f32; /* Bronze color */
}

.displayFont {
  font-size: 21px;
}
