@import url(https://fonts.googleapis.com/css?family=Montserrat);

.full-navbar {
  display: flex;
  padding: 20px 6.5%;
  background: rgb(32, 31, 31); 
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.navbar {
  list-style: none;
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.navbar img {
  margin-left: 30px;
  width: 120px;
  max-height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.navbar a, .cta {
  font-family: "Montserrat", sans-serif;
  color: #edf0f1;
  text-decoration: none;
}

.navbar li {
  padding: 0px 20px;
}

.hover-color {
  transition: color 0.3s ease 0s;
  padding: 0px 20px;
}

.hover-color:hover {
  color: #0088a9;
}

.cta {
  padding: 12px 25px;
  background-color: rgb(214, 139, 52);
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease 0s;
}

.cta:hover {
  background-color: rgba(162, 85, 3, 0.8);
}

@media (max-width: 800px) {
  .full-navbar {
    width: 180%;
    font-size: 12px;
  }

  .navbar img {
    margin-left: 10px;
    max-height: 90px;
    width: auto
  }

  .navbar li {
    padding: 0px 10px;
  }

  .navbar li:first-child {
    margin-left: 10px;
  }

  .hover-color {
    padding: 0px 0px;
  }
}